import React from 'react';
import styled from 'styled-components';

import PreviewCompatibleImage from './PreviewCompatibleImage';

const InstructorContainer = styled.div`
    padding: 1rem;
    background-color: #f5f5f5;
`;

const InstructorPreview = ({ name, about, date, featuredimage }) => {
    return (
        <InstructorContainer>
            <h3>{name}</h3>
            <p>{about}</p>
            {featuredimage && (
                <PreviewCompatibleImage
                    imageInfo={{
                        image: featuredimage,
                        alt: `featured image thumbnail for instructor ${name}`
                    }}
                />
            )}
        </InstructorContainer>
    );
}

export default InstructorPreview;
